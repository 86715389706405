import React from "react";

const ContactUs = () => {
  return (
    <div className="container">
      <h2>Contact Us</h2>
      <p><strong>Last updated on 30-01-2025 13:10:29</strong></p>

      <h3>Merchant Legal Entity Name</h3>
      <p>SUBODHAYA SETHURAMAN</p>

      <h3>Registered Address</h3>
      <p>11/49, Thiru-Vi-Ka Street, Postal Audit Colony, Saligramam, Chennai, Tamil Nadu, PIN: 600093</p>

      <h3>Operational Address</h3>
      <p>11/49, Thiru-Vi-Ka Street, Postal Audit Colony, Saligramam, Chennai, Tamil Nadu, PIN: 600093</p>

      <h3>Telephone</h3>
      <p>7200152906</p>

      <h3>Email</h3>
      <p>subodhayas@gmail.com</p>
    </div>
  );
};

export default ContactUs;
