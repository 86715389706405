import React from "react";

const RefundAndCancellation = () => {
  return (
    <div className="container">
      <h2>Cancellation & Refund Policy</h2>
      <p><strong>Last updated on 30-01-2025 13:11:31</strong></p>
      
      <p>SUBODHAYA SETHURAMAN believes in helping customers as much as possible and has a liberal cancellation policy.</p>

      <ul>
        <li>Cancellations will be considered only if requested immediately after placing the order.</li>
        <li>Cancellation requests may not be accepted if the order has already been processed.</li>
        <li>No cancellations for perishable items like flowers and food.</li>
        <li>If a product is damaged or defective, customers must report it within 7 days.</li>
        <li>Refunds, if approved, will be processed within 6-8 days.</li>
      </ul>

      <p>For any refund-related queries, please contact our support team.</p>
    </div>
  );
};

export default RefundAndCancellation;
