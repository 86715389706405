import React from "react";

const TermsAndCondition = () => {
  return (
    <div className="container">
      <h2>Terms & Conditions</h2>
      <p><strong>Last updated on 30-01-2025 13:10:55</strong></p>
      <p>These Terms and Conditions, along with the privacy policy or other terms (“Terms”), constitute a binding agreement between SUBODHAYA SETHURAMAN (“Website Owner” or “we” or “us” or “our”) and you (“you” or “your”) regarding your use of our website and services.</p>
      
      <ul>
        <li>By using our website, you agree that you have read and accepted these Terms.</li>
        <li>We reserve the right to modify these Terms at any time.</li>
        <li>You must provide true and accurate information while using our services.</li>
        <li>We do not guarantee the accuracy of the information on our website.</li>
        <li>You agree not to use the website for any illegal activities.</li>
        <li>Refunds are subject to our policies.</li>
        <li>All disputes shall be governed by the courts in Chennai, Tamil Nadu.</li>
      </ul>
      
      <p>For any concerns, please contact us at the details provided in our Contact Us page.</p>
    </div>
  );
};

export default TermsAndCondition;
